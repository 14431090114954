<template>
    <div class="wrapper">
        <Header :code="projectCode" :title="title"></Header>
        <div class="page-title icon">
            <Icon type="md-sad"/>
        </div>
        <div class="content">
            <div class="main-content">

                <div class="prompt">
                    <div class="headline">温馨提示：</div>
                    <div class="section" v-if="projectViewForm.status === 1">
                        抱歉你来晚了，{{ positionViewForm.name }}职位招聘{{ status }}，请查看<a @click="goHome(projectCode)">其他职位</a>，谢谢！
                    </div>
                    <div class="section" v-if="projectViewForm.status === 4">
                        恭喜你，登录成功！请点击右上角下拉菜单查看报名记录。
                    </div>
                    <div class="section" v-if="projectViewForm.status === 5">
                        您的链接地址有误，请核实后重新打开！
                    </div>
                </div>

            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer"
import {getProjectByCode, getPositionByCode} from "../../api/client-api";

export default {
    components: {
        Header: Header,
        Footer: Footer
    },
    data() {
        return {
            banner: null,
            title: null,
            projectViewForm: {},
            positionViewForm: {},
            projectCode: null,
            status: '',
        }
    },
    methods: {
        getProject() {
            getProjectByCode({code: this.projectCode}).then((res) => {
                if (res.data.data) {
                    this.projectViewForm = res.data.data;
                    this.projectViewForm.status = 1
                    this.title = this.projectViewForm.companyName + this.projectViewForm.name
                } else if (this.projectCode === null || this.projectCode === '') {
                    this.projectViewForm = {}
                    this.projectViewForm.status = 4
                } else {
                    this.projectViewForm = {}
                    this.projectViewForm.status = 5
                }
            }).catch((err) => {
                console.log('err===', err)
            })
        },
        getJobDetail() {
            getPositionByCode({code: this.positionCode}).then((res) => {
                if (res.data.code == 1) {
                    this.positionViewForm = res.data.data
                    if (this.positionViewForm.status === 2) {
                        this.status = '已暂停'
                    } else if (this.positionViewForm.status === 4) {
                        this.status = '已经结束'
                    }
                } else {
                    this.positionViewForm = {}
                }
            }).catch(() => {
                this.positionViewForm = {}
            })
        },
        goHome(code) {
            this.$router.push({name: 'project', params: {id: code}, query: {tab: 'position'}});
        }
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
        this.positionCode = this.$route.query.positionCode;
        this.getProject()
        this.getJobDetail()
    }
};
</script>

<style scoped>

</style>
