<template>
    <div class="wrapper has_nav_bar">
        <van-nav-bar title="温馨提示" fixed/>
        <div class="content">
            <div class="main-content">
                <div class="prompt">
                    <div class="section" v-if="projectViewForm.status === 1">
                        <van-icon name="warning-o"/>
                        <p>抱歉你来晚了，{{ positionViewForm.name }}职位招聘{{ status }}，请查看<a
                            @click="goHome(projectCode)">其他职位</a>，谢谢！</p>
                    </div>
                    <div class="section" v-if="projectViewForm.status === 4">
                        <van-icon name="smile-o"/>
                        <p>恭喜你，登录成功！请点击右上角下拉菜单查看报名记录。</p>
                    </div>
                    <div class="section" v-if="projectViewForm.status === 5">
                        <van-icon name="warning-o"/>
                        <p>您的链接地址有误，请核实后重新打开！</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
        </div>
        <div class="tab_bar">
            <van-tabbar>
                <van-tabbar-item name="home" icon="wap-home">招聘主页</van-tabbar-item>
                <van-tabbar-item :badge='messageCounts' name="message" icon="chat" @click="goMessageCenter">消息中心</van-tabbar-item>
                <van-tabbar-item name="application" icon="column" @click="goMyApplication">我的报名</van-tabbar-item>
                <van-tabbar-item name="my" icon="manager" @click="goUserCenter">个人中心</van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>

<script>
import {getProjectByCode, getPositionByCode, getCountOfUnreadMessage} from "../../api/client-api";

export default {
    data() {
        return {
            messageCounts: "",
            projectViewForm: {},
            positionViewForm: {},
            projectCode: null,
            status: '',
        }
    },
    methods: {
        goMessageCenter() {
            this.$router.push({name: 'messageCenter', query: {projectCode: this.projectCode}});
        },
        goMyApplication() {
            this.$router.push({name: 'myApplication', query: {projectCode: this.projectCode}});
        },
        goUserCenter() {
            this.$router.push({name: 'userCenter', query: {projectCode: this.projectCode}});
        },
        getProject() {
            getProjectByCode({code: this.projectCode}).then((res) => {
                if (res.data.data) {
                    this.projectViewForm = res.data.data;
                    this.projectViewForm.status = 1
                } else if (this.projectCode === null || this.projectCode === '') {
                    this.projectViewForm = {}
                    this.projectViewForm.status = 4
                } else {
                    this.projectViewForm = {}
                    this.projectViewForm.status = 5
                }
            }).catch((err) => {
                console.log('err===', err)
            })
        },
        getJobDetail() {
            getPositionByCode({code: this.positionCode}).then((res) => {
                if (res.data.code == 1) {
                    this.positionViewForm = res.data.data
                    if (this.positionViewForm.status === 2) {
                        this.status = '已暂停'
                    } else if (this.positionViewForm.status === 4) {
                        this.status = '已经结束'
                    }
                } else {
                    this.positionViewForm = {}
                }
            }).catch(() => {
                this.positionViewForm = {}
            })
        },
        goHome(code) {
            this.$router.push({name: 'project', params: {id: code}, query: {tab: 'position'}});
        },
        countOfUnreadMessage() {
            getCountOfUnreadMessage().then((res) => {
                if (res.data.data !== 0){
                    this.messageCounts = res.data.data + '';
                }
            }).catch((err)=>{
                this.messageCounts = null;
            });
        },
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
        this.positionCode = this.$route.query.positionCode;
        let that = this;
        that.isLogon = sessionStorage.getItem('Authorization') ? true : false;
        if (that.isLogon) {
            that.countOfUnreadMessage();
            that._timer = setInterval(function() {
                that.countOfUnreadMessage();
            }, 1000*60);
        }
        this.getProject()
        this.getJobDetail()
    }
};
</script>

<style scoped>

</style>
